<template>
	<div>
		<!-- 圣杯布局 -->
		<Header style="position: fixed;"></Header>
		<div style="height: 60px;"></div>
		<div class="banner" ></div>
		<div class="container">
			<div class="leftRight"></div>
			<!-- 内容 -->
			<section class="center">
				<div class="flex_items" style="background-color: #fff;height: 200px;justify-content: center;">
					<div v-for="(item,index) in list" :key="index" :style="index == 0 ? 'margin-right: 100px;' : ''">
						<p style="font-size: 22px;color: #26497E;font-weight: 600;margin-bottom: 30px;">
							{{item.title}}
						</p>
						<p style="margin-bottom: 10px;">
							<el-image :src="require('../static/images/contact/mail.png')" style="vertical-align: middle;height: 12px"></el-image>
							&nbsp;
							<span>电子邮箱：{{item.email}}</span>
						</p>
						<p>
							<el-image :src="require('../static/images/contact/L.png')" style="vertical-align: middle"></el-image>
							&nbsp;
							<span>公司地址：{{item.address}}</span>
						</p>
					</div>
				</div>
			</section>
			<div class="leftRight"></div>
		</div>
		
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "@/components/header";
	import Footer from "@/components/footer";
	export default {
		data() {
			return {
				list: [
					{title: '网宇科创（广东总部）',email: 'Bp@wangyu.com',address: '广东省东莞市南城区黄金路1号天安数码城H1栋16-17楼'},
					{title: '网宇科创（福建公司）',email: 'Bp@wangyu.com',address: '福建省厦门市集美区软件园三期F10栋'},
				],
			}
		},
		components: {
			Header,
			Footer,
		},
		mounted() {
		    
		},
		methods: {},
	}
</script>

<style scoped>
	.container {
		margin-top: 70px;
		display: flex;
		flex-direction: row;
		flex: 1;
		height: 300px;
		background: url("../static/images/contact/Mask group.jpg") no-repeat;
		background-size:100% 65%;
		background-position: center bottom;
	}
	.center {
		flex: 0 0 1200px;
	}
	.leftRight {
		flex: 1;
	}
	.footer {
		text-align: center;
	}
	.banner {
		height: 55vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url("../static/images/contact/005.jpg") no-repeat center center;
		background-size: cover; 
	}
</style>
